module.exports = {
  siteTitle: 'Martini Akande Website',
  siteTitleAlt: 'Martini Akande Portfolio and Blog',
  siteLogo: '/icons/icon-512x512.png',
  siteUrl: 'https://martiniakande.com',
  siteDescription: 'Martini Akande portfolio website and blog',
  siteRss: '/rss.xml',
  keywords: ['Martini', 'Akande', 'Filmaking', 'Filmaker', 'Portfolio', 'Blog'],
  // siteFBAppID: '',
  googleTagManagerID: 'GTM-NTN6WT3',
  GA_TRACKING_ID: 'G-831DJTY2YP',
  disqusShortname: 'martiniakande',
  userName: 'Martini Akande',
  userTwitter: 'signor_martini',
  userLocation: 'Ibadan, Nigeria',
  userDescription: 'I\'m a Mechanic, a Collaborator and an Artist',
  copyright: 'Copyright © Martini Akande 2020. All Rights Reserved.',
  themeColor: '#D86c70',
  backgroundColor: '#fff7ef'
}
